export default {
    install: function (Vue) {
        Vue.component('cs-page', () => import("./page/cs-page"));
        Vue.component('cs-page-user', () => import("./page/cs-page-user"));
        Vue.component('cs-page-setting', () => import("./page/cs-page-setting"));
        Vue.component('cs-page-docs', () => import("./page/cs-page-docs"));

        Vue.component('cs-panel', () => import("./panel/cs-panel"));
        Vue.component('cs-panel-default', () => import("./panel/cs-panel-default"));

        Vue.component('cs-banner', () => import("./cs-banner"));
        Vue.component('cs-money', () => import("./cs-money"));
        Vue.component('cs-range-picker', () => import("./cs-range-picker"));
        Vue.component('cs-dialog-form', () => import("./dialog/cs-dialog-form"));
    }
}