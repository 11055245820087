<template>
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  data() {
    return {
      zhCN,
    };
  },
  provide() {
    return {
      loadUserInfo: this.loadUserInfo,
    };
  },
  mounted() {
    this.checkLoginUser();
  },
  methods: {
    //检测在同一浏览器中，是否更换了登录用户
    checkLoginUser() {
      window.addEventListener("storage", (e) => {
        if (this.$store.getters.isChangeUser(e.key)) {
          location.reload();
        }
      });
    },
    loadUserInfo() {
      // 获取个人信息
      this.api.service
        .uac_oapi_sso_checkLogin()
        .then(async ({ code, data, msg }) => {
          await this.api.toast({ code, msg }, 0);
          // 没有头像，则使用默认头像
          if (!data.headUrl) {
            data.headUrl = this.api.getPublicFile("img/avatar/0.png");
          }
          // 设置用户ID
          data.id = data.userName;
          this.$store.commit("setUserInfo", data);
        });
    },
  },
};
</script>

<style>
html,
body,
#app {
 margin:0;
 padding:0;
}
</style>