import Vue from 'vue'
import { DEV_HOSTNAME } from '@/config/const'

function getBasePath() {
    let hostname = location.hostname
    // 本地
    if (hostname === 'localhost') {
        hostname = DEV_HOSTNAME
    }
    const arr = hostname.split('.')
    let env = 'pro'
    // 二级域名
    if (arr.length == 3) {
        env = arr[0]
    }
    if (env === 'www') env = 'pro'
    return `/${env}/api/`
}
const BASE_PATH = getBasePath()

// 拦截器
export default new Proxy({
    module: {
        fpm: 'fin-portal-manage',
        fpc: 'fin-portal-center'
    }
}, {
    get: function (target, propKey) {
        return async function () {
            const { http } = Vue.api
            let arr = propKey.split(propKey.includes('/') ? '/' : '_')
            let url;
            // 直接使用绝对URL路径
            if (arr[0] === '') {
                url = propKey
            } else {
                const module = target.module[arr[0]]
                if (module) {
                    arr[0] = module
                }
                url = `${BASE_PATH}${arr.join('/')}`
            }
            return http.post(url, ...arguments).finally(() => {

            })
        };
    }
});