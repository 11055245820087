export default {
    index: {
        title: '个人中心',
    },
    order: {
        title: '订单管理'
    },
    orderDetails: {
        title: '订单详情',
        checkLogin: false
    },
    ocrService: {
        title: 'OCR服务'
    },
    company: {
        title: '企业信息'
    },
    setting: {
        title: '账号设置'
    },
    logout: {
        title: '注销账号'
    },
    setTel: {
        title: '修改手机号'
    },
    resetPassword: {
        title: '重置密码'
    }
}