import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
// 引入UI框架
import antd from './plugins/antd'
Vue.use(antd)
// 引入表格
import 'xe-utils'
import VXETable from 'vxe-table'
Vue.use(VXETable)
// 默认格式
VXETable.formats.add('defaultText', ({ cellValue }) => {
  return cellValue || cellValue === 0 ? cellValue : '--'
})
// 兼容老旧浏览器
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str, newStr) {
    return this.replace(new RegExp(str, 'g'), newStr)
  }
}

import './style/index.scss'

import api from './plugins/api'
Vue.use(api, { router, store })
import globalComponents from './components/global'
Vue.use(globalComponents)

Vue.config.productionTip = false
import './plugins/flexible/rem'
import VantGreen from 'vant-green';
import 'vant-green/lib/index.css';
Vue.use(VantGreen);
import './plugins/baidu' // 百度统计代码
Vue.prototype.getBrowser = document.body.clientWidth < 1024 ? 'phone' : 'pc'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
