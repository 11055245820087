import MoutedAPI from '@zhululu/finance/src/api/index'
import extend from '@zhululu/finance/src/extend/index'
import components from '@zhululu/finance/src/components'
import api from '../api/index'
import service from '../api/service'
import validate from '../api/validate'
import { DEV_HOSTNAME } from '@/config/const'

export default {
    install: function (Vue, { router, store }) {
        // const isProduction = process.env.NODE_ENV === 'production'
        // 安装api
        Vue.api = Vue.prototype.api = MoutedAPI({
            api,
            router,
            store,
            service,
            validate,
            storage: {
                prefix: 'CENTER-',
                constant: [
                    'userInfo',
                    'history'
                ]
            },
            components: {
                DialogConfirm: () => import("@/components/global/dialog/DialogConfirm")
            },
            http: {
                baseURL: `https://${location.hostname === 'localhost' ? DEV_HOSTNAME : location.hostname}`,
                origin: location.origin,
                domain: location.origin,
                // 拦截请求配置
                config(cfg) {
                    const token = Vue.api.storage.getCookie("UCS-AUTHEN-TOKEN")
                    // console.log(token)
                    cfg.headers['ucs-authen-token'] = token
                },
                //成功处理
                success(response) {
                    const res = response.data
                    try {
                        // 状态码为1，则未登录
                        if (res.code == 1) {
                            Vue.api.storage.removeCookie("UCS-AUTHEN-TOKEN")
                            Vue.api.storage.removeCookie("sso_sys_token")
                            // 清除本地用户信息
                            store.commit("setUserInfo", {});
                            // 判断当前页面是否需要权限
                            const { meta } = router.currentRoute
                            if (meta && meta.checkLogin !== false) {
                                // 跳转到登录页面
                                location.href = process.env.VUE_APP_CONTEXT_PATH + 'login'
                            }
                        }
                    } catch (ex) {
                        console.error(ex)
                    }
                    return res;
                },
                error(err) {
                    const res = err.response
                    const data = {
                        code: 1001,
                        msg: '服务异常'
                    }
                    // 异常信息
                    if ([500, 503].includes(res.status)) {
                        data.msg = `${res.data.msg}`
                    }
                    else if (res.status == 502) {
                        data.msg = '网关超时'
                    }
                    Vue.api.toast(data)
                }
            }
        })
        // 注册指令
        Object.keys(extend.directive).forEach(key => {
            Vue.directive(key, extend.directive[key])
        })
        // 注册过滤器
        Object.keys(extend.filter).forEach(key => {
            Vue.filter(key, extend.filter[key])
        })
        // 注册异常监控
        // extend.exception({ name: 'manage' })
        // 注册组件
        Vue.use(components, ['CsIcon', 'CsDialog', 'CsTable', 'CsCountdown', 'CsQrcode'])
    }
}