import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import store from '@zhululu/finance/src/extend/store'

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async loadUserInfo(context, api) {
      // 获取个人信息
      let res = null;
      res = await api.service.uac_oapi_sso_checkLogin()
      await api.toast(res, 0);
      let { data } = res
      // 没有头像，则使用默认头像
      if (!data.headUrl) {
        data.headUrl = api.getHeadUrl("0.png");
      }
      // 设置用户ID
      data.id = data.userName;
      context.commit('setUserInfo', data)
    }
  },
  modules: {
    store
  }
})
