export default {
    index: {
        title: '产品',
        checkLogin: false
    },
    addOrder: {
        title: "下单",
        checkLogin: false
    },
    payOrder: {
        title: "订单"
    },
    success: {
        title: "下单"
    }
}