
import { message } from 'ant-design-vue'
import { DEV_HOSTNAME } from '@/config/const'
// 配置全局弹窗
message.config({
    top: `40%`,
    duration: 3,
    maxCount: 3
});

export default class {
    toast(params, flags) {
        return new Promise((resolve, reject) => {
            if (this.validate.isObject(params)) {
                const { code, msg } = params
                if (code == '0') {
                    // 如果标记为0，则不弹信息
                    if (flags !== 0) {
                        message.success(msg)
                    }
                    resolve()
                } else {
                    // 如果是未登录，则不提示
                    if (code != '1') message.error(msg)
                    reject()
                }
            }
        })
    }

    confirm(message, flags) {
        const data = {
            message
        }
        if (flags === 0) {
            data['submitText'] = '我已经知晓'
            data['hideCancel'] = true
        }
        return this.component("DialogConfirm", { data })
    }

    loading(content, cfg = {}) {
        // 配置
        const config = {
            key: Date.now(),
            content,
            duration: 0,
            ...cfg
        }
        const $this = this
        // 返回值
        const res = {
            close() {
                if (arguments.length > 0) {
                    const cfg = {
                        key: config.key
                    }
                    if (arguments.length > 1) {
                        Object.assign(cfg, arguments[1])
                    }
                    $this.toast(arguments[0], cfg)
                } else {
                    message.destroy()
                }
            }
        }
        // 如果设置延时，则返回Promise
        if ('delay' in config) {
            return new Promise((resolve) => {
                (async () => {
                    message.loading(config)
                    await this.sleep(config['delay'])
                    resolve(res)
                })();
            })
        } else {
            message.loading(config)
        }
        return res
    }

    getPublicFile(url) {
        return location.origin + process.env.VUE_APP_CONTEXT_PATH + url
    }

    // 登出
    logout() {
        this.storage.removeCookie("UCS-AUTHEN-TOKEN");
        this.storage.removeCookie("sso_sys_token");
        // 清除本地用户信息
        this.store.commit("setUserInfo", {});
        this.router.push("/login");
    }

    //
    animateCSS(node, animation, prefix = 'animate__') {
        return new Promise((resolve) => {
            const animationName = `${prefix}${animation}`;
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });
    }

    // 固定小数位
    toFixed(precision, num) {
        return Number(num.toFixed(precision))
    }

    // 获取头像
    getHeadUrl(filename) {
        const url = `/portal-web/img/avatar/${filename}`
        let origin = location.origin
        if (location.hostname === 'localhost') {
            origin = 'https://'+ DEV_HOSTNAME
        }
        return origin + url
    }
}