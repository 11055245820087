export default {
    403: {
        title: '没有权限',
        checkLogin: false
    },
    404: {
        title: '页面不存在',
        checkLogin: false
    },
    mobile: {
        title: '移动端访问',
        checkLogin: false
    },
    redirect: {
        title: '重定向',
        checkLogin: false
    }
}