(function (win, doc) {
    //  win, doc
      let resizeEvt = 'orientationchange' in win ? 'orientationchange' : 'resize';
      function recalc() {
      //  获取屏幕宽度
        let nWidth = win.innerWidth || document.documentElement.clientWidth;
        document.documentElement.style.fontSize = nWidth * 16 / 375 + 'px';
      }
      //  dom结构加载完成，就出发一下函数
      doc.addEventListener('DOMContentLoaded', recalc);
      win.addEventListener(resizeEvt, recalc);
    })(window, document);
    