import { formatRouter } from '@zhululu/finance/src/extend/router'

import home from './home'
import product from './product'
import user from './user'
import docs from './docs'
import error from './error'
import learn from './learn'

// 获取子视图
function getChildren(obj) {
    const children = []
    Object.keys(obj).forEach(key => {
        children.push(...formatRouter(key, obj[key]));
    })
    return children
}

export default [
    {
        path: '/',
        redirect: '/home/index'
    },
    {
        path: '/redirect',
        redirect: '/error/redirect'
    },
    {
        path: '/layout/login',
        component: () => import('@/views/layout/login'),
        children: [
            {
                path: '/login',
                meta: {
                    checkLogin: false
                },
                component: () => import('@/views/system/login'),
            },
            {
                path: '/register',
                meta: {
                    checkLogin: false
                },
                component: () => import('@/views/system/register'),
            }
        ]
    },
    {
        path: '/third-login',
        component: () => import('@/views/layout/third-login'),
        meta: {
            checkLogin: false
        }
    },
    ...getChildren({ home, product, user, docs, error, learn }),
    {
        path: '*',
        redirect: '/error/404'
    }
]