import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from '../store'
import { validateRouter } from '@zhululu/finance/src/extend/router'

import routes from './routes/index'
const router = new VueRouter({
  base: process.env.VUE_APP_CONTEXT_PATH,
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

validateRouter({
  router, store,
  onBeforeEach(to, from, next) {
    if (to.path === '/login') {
      location.replace('/ucs')
      return
    }
    if (window._hmt) {
      if (to.path) {
        window._hmt.push(['_trackPageview', '/#' + to.fullPath])
      }
    }
    
    // 如果token不存在，则跳转到登录
    const token = Vue.api.storage.getCookie('UCS-AUTHEN-TOKEN')
    if (to.meta && to.meta.checkLogin !== false && !token) {
      next({ path: '/login', query: { redirectUrl: to.fullPath } })
    } else {
      next()
    }
  },
  onError() {
    window.alert("当前系统有新版本发布，点击确定重新加载页面")
    location.reload()
  }
})

export default router
