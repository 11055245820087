import {
    Base,
    message,
    notification,
    Affix,
    Anchor,
    AutoComplete,
    Alert,
    Avatar,
    BackTop,
    Badge,
    Breadcrumb,
    Button,
    Calendar,
    Card,
    Collapse,
    Carousel,
    Cascader,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    FormModel,
    Icon,
    Input,
    InputNumber,
    Layout,
    List,
    // LocaleProvider,
    Menu,
    Mentions,
    Modal,
    Pagination,
    Popconfirm,
    Popover,
    Progress,
    Radio,
    Rate,
    Row,
    Select,
    Slider,
    Spin,
    Statistic,
    Steps,
    Switch,
    Transfer,
    Tree,
    TreeSelect,
    Tabs,
    Tag,
    TimePicker,
    Timeline,
    Tooltip,
    Upload,
    Drawer,
    Skeleton,
    Comment,
    ConfigProvider,
    Empty,
    Result,
    Descriptions,
    Space,
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';

export default {
    install: function (Vue) {
        [
            Base,
            Affix,
            Anchor,
            AutoComplete,
            Alert,
            Avatar,
            BackTop,
            Badge,
            Breadcrumb,
            Button,
            Calendar,
            Card,
            Collapse,
            Carousel,
            Cascader,
            Checkbox,
            Col,
            DatePicker,
            Divider,
            Dropdown,
            Form,
            FormModel,
            Icon,
            Input,
            InputNumber,
            Layout,
            List,
            // LocaleProvider,
            Menu,
            Mentions,
            Modal,
            Pagination,
            Popconfirm,
            Popover,
            Progress,
            Radio,
            Rate,
            Row,
            Select,
            Slider,
            Spin,
            Statistic,
            Steps,
            Switch,
            Transfer,
            Tree,
            TreeSelect,
            Tabs,
            Tag,
            TimePicker,
            Timeline,
            Tooltip,
            Upload,
            Drawer,
            Skeleton,
            Comment,
            // ColorPicker,
            ConfigProvider,
            Empty,
            Result,
            Descriptions,
            Space,
        ].map(component => {
            Vue.use(component);
        });
        Vue.prototype.$message = message;
        Vue.prototype.$notification = notification;
        Vue.prototype.$info = Modal.info;
        Vue.prototype.$success = Modal.success;
        Vue.prototype.$error = Modal.error;
        Vue.prototype.$warning = Modal.warning;
        Vue.prototype.$confirm = Modal.confirm;
        Vue.prototype.$destroyAll = Modal.destroyAll;
    }
}