export default class {
    password(val) {
        // 6~20位数字或字母
        if (!/^[a-zA-Z0-9]{6,20}$/.test(val)) {
            return "密码必须为6~20位数字或字母"
        }
        if (this.isSerialNumber(val)) {
            return "密码不可使用连续数字"
        }
        if (this.isSameChar(val)) {
            return "密码不可使用连续相同字符"
        }
    }
    
    // 判断是否移动端
    isMobileOS() {
        return 'ontouchend' in document.body
    }

    // 是否连续数字
    isSerialNumber(str) {
        if (str.length < 2) return false
        for (let i = 0, l = str.length; i < l - 1; i++) if (Math.abs((str.charCodeAt(i) - str.charCodeAt(i + 1))) !== 1) return false
        return true
    }

    // 是否相同字符
    isSameChar(str) {
        return /^(.)\1+$/.test(str)
    }
}