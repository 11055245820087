export default {
    user: {
        title: '使用协议',
        checkLogin: false
    },
    privacy: {
        title: '隐私政策',
        checkLogin: false
    },
    help: {
        title: '帮助文档',
        checkLogin: false
    }
}